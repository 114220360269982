import React, { Component } from "react";
import { Segment, Header, Loader } from "semantic-ui-react";
import PaginateWrapper from "../common/PaginateWrapper";
import CompanyList from "../common/lists/companyList";
import api from "../../../actions/api";
import commonFunctions from "../../../commonFunctions";

class companyListDash extends Component {

  state = {
    admin_style_data: {},
    loading: false,
  };

  componentDidMount() {

    this.setState({loading: true});

    api.company.myCompany()
      .then((res) => {
        this.setState({admin_style_data: res.admin_style_data}, () => {
            this.setState({loading: false});
        });
      });
  }

  getCompanies = (pageNum, pageSize, search, filters) => {
    return api.company.getAllPaged({
      pageNum,
      pageSize,
      search,
      filters,
    });
  };

  getStyle(style, hover, exclude = []){

    let result = commonFunctions.getStyle(this.state.admin_style_data,style,hover,exclude);

    if(this.state.hovered == hover){
      return result.finalHover;
    }else{
      return result.finalStyle;
    }
  }

  render() {

    if(this.state.loading){
      return <Loader active indeterminate size="large">
      <h2>Please wait...</h2>
    </Loader>;
    }

    return (
      <Segment basic className="no-pad">
        <Header as={"h1"} textAlign="center" className=" pageTitle" style={this.getStyle("companies_header", `companies_header`)}>
          Company List
        </Header>
        <Segment className="border">
          <PaginateWrapper
            dataQuery={this.getCompanies}
            search={true}
            archived_toggle={{ title: "Archived", field: "archived" }}
            active={"Archived"}
            render={(items) => (
              <CompanyList
                style={this.state.admin_style_data}
                data={items}
                action={(id) => this.props.history.push(`/company/${id}`)}
              />
            )}
          />
        </Segment>
      </Segment>
    );
  }
}

export default companyListDash;
